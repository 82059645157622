import "./App.css";
import HomePage from "./homePage";
import { BrowserRouter as Router } from "react-router-dom";
function App(props) {
  return (
    <Router>
      <HomePage />
    </Router>
  );
}

export default App;
